.Header {
  background-color: rgb(75, 75, 75);
  border-bottom: 2px solid #66cc99;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.Header h3 {
  margin: 10px;
  padding-right: 50px;
}

.HeaderContainer:visited {
  color: white;
}
.HeaderContainer a {
  color: white;
  text-decoration: none;
}

.HeaderContainer a:hover {
  text-decoration: none;
}

.Link {
  color:white;
  margin: 10px auto;
  padding-right: 25px;
}

.Link:active {
  color: #66cc99;
}

.HeaderContainer a:active {
  color: #66cc99;
}

.Link:hover {
  color:#66cc99;
  margin: 10px auto;
}

.HeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: white;
}

@media only screen and (max-width: 850px) {
  .Header h3 {
    margin: 10px;
    padding-right: 5px;
    font-size: 1em;
  }
  .Link {
    color:white;
    margin: 10px auto;
    padding-right: 5px;
  }
}
