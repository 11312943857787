section {
  min-height: 200px;
  background-color: #363636;
  border: 2px solid #66cc99;
  border-radius: 3px;
  margin-bottom: 20px;
  color:rgb(190, 190, 190);
  width: 98%;
  margin: 0 auto;
}

.ContainerRight {
  width: 90%;
  margin: 15px;
  margin-right: 5%;
  border-radius: 5px;

}

.CarouselBox {
  width: 40%;
  margin: 15px 5%;
  display: none;
  border: 2px solid rgb(194, 194, 194);
  border-radius: 3px;
}

.carousel {
  max-width: 600px;
  width: auto !important;
  margin: 0 auto;
}

.thumbs-wrapper {
  display: none;
}

.project-Description {
  border-bottom: 2px solid #66cc99;
  padding: 15px;
}

.project-Container {
  height: auto;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.CarouselBoxMobile {
  display: flex;
  width: 90%;
  margin: 15px 5%;
}

.project-Container h3 {
  font-size: 30px;
  margin: 10px auto;
  color: #66cc99;
}

.header-Name {
  color: white;
  font-size: 24px;
  margin-bottom: 0;
}

.project-IMG-Mobile {
  height: auto;
  margin: 15px 5%;
  max-width: 600px;
  width: 90%;
  display: flex;
}

.project-IMG {
  height: auto;
  margin: 15px 5%;
  max-width: 600px;
  width: 80%;
  display: none;
  border: 2px solid rgb(194, 194, 194);
  border-radius: 3px;
}

.ui-icon-jquery {
  width: 35px;
  height: auto;
  filter: grayscale(100%);
  margin-top:5px;
}
.fab {
  font-size: 25px;
}
.CarouselBoxTop {
  margin: 15px 5%;
  border: 2px solid rgb(194, 194, 194);
  border-radius: 3px;
}

@media only screen and (min-width: 850px) {
  section {
    width: 98%;
    margin: 0 auto;
  }

  .CarouselBox {
    width: 40%;
    margin: 15px 5%;
    display: block;
  }

  .project-Container {
    height: auto;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .CarouselBoxMobile {
    display: none;
  }

  .project-IMG-Mobile {
    height: auto;
    margin: 15px 5%;
    max-width: 600px;
    width: 90%;
    display: none;
  }
  
  .project-IMG {
    height: auto;
    margin: 15px 5%;
    max-width: 600px;
    width: 80%;
    display: flex;
  }

  .ContainerRight {
    width: 60%;
    margin: 15px;
    margin-right: 5%;
    border-radius: 5px;
  }
}