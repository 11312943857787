@import url(https://fonts.googleapis.com/css?family=Varela+Round);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

body {
  font-family: "Manjari", sans-serif;
  font-size: 20px;
  color: rgb(190, 190, 190);
  text-align: center;
  line-height: 1.5;
  background-color: rgb(34, 34, 34);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.Header {
  background-color: rgb(75, 75, 75);
  border-bottom: 2px solid #66cc99;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.Header h3 {
  margin: 10px;
  padding-right: 50px;
}

.HeaderContainer:visited {
  color: white;
}
.HeaderContainer a {
  color: white;
  text-decoration: none;
}

.HeaderContainer a:hover {
  text-decoration: none;
}

.Link {
  color:white;
  margin: 10px auto;
  padding-right: 25px;
}

.Link:active {
  color: #66cc99;
}

.HeaderContainer a:active {
  color: #66cc99;
}

.Link:hover {
  color:#66cc99;
  margin: 10px auto;
}

.HeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: white;
}

@media only screen and (max-width: 850px) {
  .Header h3 {
    margin: 10px;
    padding-right: 5px;
    font-size: 1em;
  }
  .Link {
    color:white;
    margin: 10px auto;
    padding-right: 5px;
  }
}

.HomepageContainer {
  width: 97%;
  margin: 0 auto;
  margin-top: 25px;
}
section {
  min-height: 200px;
  background-color: #363636;
  border: 2px solid #66cc99;
  border-radius: 3px;
  margin-bottom: 20px;
  color:rgb(190, 190, 190);
  width: 98%;
  margin: 0 auto;
}

.ContainerRight {
  width: 90%;
  margin: 15px;
  margin-right: 5%;
  border-radius: 5px;

}

.CarouselBox {
  width: 40%;
  margin: 15px 5%;
  display: none;
  border: 2px solid rgb(194, 194, 194);
  border-radius: 3px;
}

.carousel {
  max-width: 600px;
  width: auto !important;
  margin: 0 auto;
}

.thumbs-wrapper {
  display: none;
}

.project-Description {
  border-bottom: 2px solid #66cc99;
  padding: 15px;
}

.project-Container {
  height: auto;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.CarouselBoxMobile {
  display: flex;
  width: 90%;
  margin: 15px 5%;
}

.project-Container h3 {
  font-size: 30px;
  margin: 10px auto;
  color: #66cc99;
}

.header-Name {
  color: white;
  font-size: 24px;
  margin-bottom: 0;
}

.project-IMG-Mobile {
  height: auto;
  margin: 15px 5%;
  max-width: 600px;
  width: 90%;
  display: flex;
}

.project-IMG {
  height: auto;
  margin: 15px 5%;
  max-width: 600px;
  width: 80%;
  display: none;
  border: 2px solid rgb(194, 194, 194);
  border-radius: 3px;
}

.ui-icon-jquery {
  width: 35px;
  height: auto;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  margin-top:5px;
}
.fab {
  font-size: 25px;
}
.CarouselBoxTop {
  margin: 15px 5%;
  border: 2px solid rgb(194, 194, 194);
  border-radius: 3px;
}

@media only screen and (min-width: 850px) {
  section {
    width: 98%;
    margin: 0 auto;
  }

  .CarouselBox {
    width: 40%;
    margin: 15px 5%;
    display: block;
  }

  .project-Container {
    height: auto;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .CarouselBoxMobile {
    display: none;
  }

  .project-IMG-Mobile {
    height: auto;
    margin: 15px 5%;
    max-width: 600px;
    width: 90%;
    display: none;
  }
  
  .project-IMG {
    height: auto;
    margin: 15px 5%;
    max-width: 600px;
    width: 80%;
    display: flex;
  }

  .ContainerRight {
    width: 60%;
    margin: 15px;
    margin-right: 5%;
    border-radius: 5px;
  }
}
.AboutContainer {
  background-color: rgba(0,0,0, 0.4); 
  border: 3px solid #66cc99;
  padding: 25px;
  border-radius: 3px;
  margin: 15px 15%;
}

@media only screen and (max-width: 850px) {
  .AboutContainer {
    background-color: rgba(0,0,0, 0.4); 
    border: 3px solid #66cc99;
    padding: 25px;
    border-radius: 3px;
    margin: 15px 5%;
  }
}
*,
*::after,
*::before {
  box-sizing: border-box; }

html,
body {
  height: 100%; }

.list-titles img {
  width: 25px;
  height: 25px;
  margin: 0 auto; }

.list-titles {
  list-style-type: none;
  margin: 0 auto;
  padding-left: 0px; }

.ResumeIcon {
  height: 23px;
  width: 23px; }

.control-dots {
  padding-left: 0; }

.Next {
  color: black; }

body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 1.5em; }

a {
  color: #66cc99;
  text-decoration: none; }

.clearfix::after,
.clearfix::before {
  content: " ";
  display: table; }

.clearfix::after {
  clear: both; }

.bold {
  color: #4a4e51;
  font-weight: 400; }

.ResumeContainer {
  width: 97%;
  margin: 0 auto;
  margin-top: 25px; }
  .ResumeContainer h1 {
    color: #66cc99; }

.resume-wrapper {
  position: relative;
  text-align: center;
  height: 100%; }

.container {
  min-height: 600px; }

.profile {
  background: #fff;
  width: 28%;
  float: left;
  border: 2px solid #66cc99;
  margin-right: 2%;
  color: #9099a0; }
  @media (max-width: 850px) {
    .profile {
      width: 100%; } }
  .profile .name-wrapper {
    float: left;
    width: 60%; }
  .profile h1 {
    font-size: 2em;
    text-align: left;
    font-family: "Varela Round", sans-serif;
    color: #4a4e51;
    text-transform: uppercase;
    line-height: 1em;
    padding-top: 40px; }
    @media (max-width: 1200px) {
      .profile h1 {
        margin-top: 0px;
        margin-bottom: 0px;
        padding-top: 0; } }
    @media (max-width: 450px) {
      .profile h1 {
        font-size: 1.8em;
        padding-top: 0; } }
  .profile li {
    margin-bottom: 10px; }
  .profile .picture-resume-wrapper {
    width: 40%;
    display: block;
    float: left; }
    @media (max-width: 1200px) {
      .profile .picture-resume-wrapper {
        width: 100%; } }
  .profile .picture-resume {
    width: 220px;
    height: 220px;
    background-size: cover;
    border-radius: 50%;
    margin-right: 0px;
    display: table-cell;
    position: relative;
    vertical-align: middle; }
    .profile .picture-resume span {
      display: table-cell;
      vertical-align: middle;
      position: relative;
      margin: 0 auto;
      z-index: 10;
      text-align: center; }
    .profile .picture-resume img {
      border-radius: 50%;
      width: 130px; }
      @media (max-width: 1500px) {
        .profile .picture-resume img {
          width: 80px;
          padding: 0; } }
      @media (max-width: 1200px) {
        .profile .picture-resume img {
          width: 120px;
          height: 120px;
          padding: 0; } }
    @media (max-width: 1500px) {
      .profile .picture-resume {
        width: 150px;
        height: 150px; } }
    @media (max-width: 1200px) {
      .profile .picture-resume {
        width: 200px;
        height: 200px; } }
    @media (max-width: 450px) {
      .profile .picture-resume {
        width: 180px;
        height: 180px; } }
  .profile .contact-info {
    margin-top: 10px;
    font-weight: 300; }
    @media (max-width: 1200px) {
      .profile .contact-info {
        margin-top: 10px; } }
    @media (max-width: 450px) {
      .profile .contact-info {
        margin-top: 10px; } }
  .profile .list-titles {
    float: left;
    text-align: left;
    font-weight: 600;
    color: #4a4e51; }
  .profile .list-content {
    float: left;
    text-align: left;
    font-weight: 300;
    margin: 0 auto;
    padding-left: 25px; }
  .profile .contact-presentation {
    text-align: left;
    font-weight: 300;
    margin-top: 10px;
    margin-bottom: 10px; }
    @media (max-width: 1200px) {
      .profile .contact-presentation {
        margin-top: 10px;
        margin-bottom: 10px; } }
    @media (max-width: 850px) {
      .profile .contact-presentation {
        margin-top: 10px;
        margin-bottom: 10px; } }
  @media (max-width: 1200px) {
    .profile .SkillList {
      margin-right: 20px; } }
  @media (max-width: 850px) {
    .profile .SkillList {
      margin-right: 20px; } }
  .profile svg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0; }
  .profile .st0,
  .profile .st1 {
    fill: #66cc99; }

.experience {
  background: #3d3e42;
  width: 70%;
  float: left;
  position: relative;
  border: 2px solid #66cc99;
  color: #9099a0;
  font-weight: 300;
  min-height: 100%;
  min-height: 100vh; }
  @media (max-width: 850px) {
    .experience {
      width: 100%; } }
  .experience h3.experience-title {
    color: #66cc99;
    text-align: left;
    text-transform: uppercase;
    font-size: 1.2em;
    margin-bottom: 20px;
    font-weight: 400; }
  .experience .company-wrapper {
    width: 30%;
    float: left;
    text-align: left;
    padding-right: 5%;
    margin-bottom: 10px; }
    @media (max-width: 450px) {
      .experience .company-wrapper {
        width: 100%;
        margin-bottom: 5px; } }
  .experience .job-wrapper {
    width: 70%;
    float: left;
    text-align: left;
    padding-right: 5%;
    margin-bottom: 10px; }
    @media (max-width: 450px) {
      .experience .job-wrapper {
        width: 100%;
        margin-bottom: 5px; } }
  .experience .experience-title {
    color: white;
    margin-bottom: 15px; }

.section-padding {
  padding: 15px 25px; }
  @media (max-width: 850px) {
    .section-padding {
      padding: 15px 15% 15px 10%; } }
  @media (max-width: 450px) {
    .section-padding {
      padding: 5%; } }

.section-wrapper {
  width: 95%;
  float: left;
  text-align: left;
  color: #9099a0;
  font-weight: 300;
  margin-bottom: 20px; }
  @media (max-width: 450px) {
    .section-wrapper {
      width: 100%; } }
  .section-wrapper:nth-child(3) {
    padding-right: 8%; }
  .section-wrapper h3.section-title {
    color: #66cc99;
    text-align: left;
    text-transform: uppercase;
    font-size: 1.2em;
    margin-bottom: 20px;
    margin-top: 0;
    font-weight: 400; }
  .section-wrapper .skill-percentage {
    margin-bottom: 10px;
    position: relative;
    color: black; }
    .section-wrapper .skill-percentage::after {
      content: "";
      width: 100%;
      height: 6px;
      background: #4a4e51;
      display: block;
      margin-top: 3px; }
    .section-wrapper .skill-percentage::before {
      content: "";
      height: 6px;
      background: #66cc99;
      position: absolute;
      margin-top: 3px;
      bottom: 0; }
    .section-wrapper .skill-percentage:nth-child(1)::before {
      width: 85%;
      -webkit-animation: skill_1 0.6s ease;
              animation: skill_1 0.6s ease; }
    .section-wrapper .skill-percentage:nth-child(2)::before {
      width: 90%;
      -webkit-animation: skill_2 0.6s ease;
              animation: skill_2 0.6s ease; }
    .section-wrapper .skill-percentage:nth-child(3)::before {
      width: 70%;
      -webkit-animation: skill_3 0.6s ease;
              animation: skill_3 0.6s ease; }
    .section-wrapper .skill-percentage:nth-child(4)::before {
      width: 75%;
      -webkit-animation: skill_4 0.6s ease;
              animation: skill_4 0.6s ease; }
    .section-wrapper .skill-percentage:nth-child(5)::before {
      width: 70%;
      -webkit-animation: skill_5 0.6s ease;
              animation: skill_5 0.6s ease; }
    .section-wrapper .skill-percentage:nth-child(6)::before {
      width: 65%;
      -webkit-animation: skill_6 0.6s ease;
              animation: skill_6 0.6s ease; }
    .section-wrapper .skill-percentage:nth-child(7)::before {
      width: 85%;
      -webkit-animation: skill_6 0.6s ease;
              animation: skill_6 0.6s ease; }

@-webkit-keyframes skill_1 {
  from {
    width: 0%; }
  to {
    width: 85%; } }

@keyframes skill_1 {
  from {
    width: 0%; }
  to {
    width: 85%; } }

@-webkit-keyframes skill_2 {
  from {
    width: 0%; }
  to {
    width: 90%; } }

@keyframes skill_2 {
  from {
    width: 0%; }
  to {
    width: 90%; } }

@-webkit-keyframes skill_3 {
  from {
    width: 0%; }
  to {
    width: 70%; } }

@keyframes skill_3 {
  from {
    width: 0%; }
  to {
    width: 70%; } }

@-webkit-keyframes skill_4 {
  from {
    width: 0%; }
  to {
    width: 75%; } }

@keyframes skill_4 {
  from {
    width: 0%; }
  to {
    width: 75%; } }

@-webkit-keyframes skill_5 {
  from {
    width: 0%; }
  to {
    width: 70%; } }

@keyframes skill_5 {
  from {
    width: 0%; }
  to {
    width: 70%; } }

@-webkit-keyframes skill_6 {
  from {
    width: 0%; }
  to {
    width: 65%; } }

@keyframes skill_6 {
  from {
    width: 0%; }
  to {
    width: 65%; } }

@-webkit-keyframes skill_7 {
  from {
    width: 0%; }
  to {
    width: 85%; } }

@keyframes skill_7 {
  from {
    width: 0%; }
  to {
    width: 85%; } }


