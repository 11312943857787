* {
  box-sizing: border-box;
}

body {
  font-family: "Manjari", sans-serif;
  font-size: 20px;
  color: rgb(190, 190, 190);
  text-align: center;
  line-height: 1.5;
  background-color: rgb(34, 34, 34);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
